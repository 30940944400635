var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap do_payment_pop",
      staticStyle: { width: "1000px", height: "550px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM099P010.001"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content_box" },
          [
            _c("DOPaymentTarget", {
              ref: "DOPaymentTarget",
              attrs: { "bl-list": _vm.freightBillInfo.blList },
              on: {
                businessSetting: _vm.businessSetting,
                setTaxInvoice: _vm.setTaxInvoice,
                setPaidYn: _vm.setPaidYn,
                setIsTaxValidCheck: _vm.setIsTaxValidCheck,
              },
            }),
            _c("FreightBillDmdRadio", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow.dmdRadio,
                  expression: "isShow.dmdRadio",
                },
              ],
              ref: "FBDmdRadio",
              attrs: {
                "dmd-cat-cd": _vm.freightBillInfo.dmdCatCd,
                "ori-dmd-cat-cd": _vm.oriDmdCatCd,
              },
              on: { child: _vm.getChildData },
            }),
            _c("FreightBillInfo", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow.info,
                  expression: "isShow.info",
                },
              ],
              ref: "FBInfo",
              attrs: {
                "dp-id": _vm.freightBillInfo.dpId,
                "bl-list": _vm.freightBillInfo.blList,
                "collect-bl-list": _vm.freightBillInfo.collectBlList,
              },
              on: { child: _vm.getChildData },
            }),
            _c("FreightBillMethod", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow.method,
                  expression: "isShow.method",
                },
              ],
              ref: "FBMethod",
              attrs: {
                "err-arr": _vm.errArr.method,
                "clear-arr": _vm.clearArr.method,
                "dp-id": _vm.freightBillInfo.dpId,
                "btn-type": _vm.freightBillInfo.btnType,
              },
              on: { child: _vm.getChildData },
            }),
            _c("FreightBillTax", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow.taxTemplate,
                  expression: "isShow.taxTemplate",
                },
              ],
              ref: "FBTax",
              attrs: {
                "err-arr": _vm.errArr.taxTemplate,
                "clear-arr": _vm.clearArr.taxTemplate,
                "dp-id": _vm.freightBillInfo.dpId,
                "btn-type": _vm.freightBillInfo.btnType,
                "validation-tax-template": _vm.validationTaxTemplate,
                "bl-list": _vm.freightBillInfo.blList,
              },
              on: { setReqCont: _vm.setReqCont },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.payment()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.002")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.003")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }