<template>
  <div class="popup_wrap do_payment_pop" style="width:1000px; height: 550px;">
    <button class="layer_close" @click="close()">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.ONIM099P010.001') }}</h1> <!-- D/O 발행신청 -->
      <div class="content_box">
        <!-- <DOPaymentTarget
          ref="DOPaymentTarget"
          :bl-list="freightBillInfo.blList"
          @businessSetting="businessSetting"
          @setTaxInvoice="setTaxInvoice"
          @setPaidYn="setPaidYn"
          @setIsTaxValidCheck="setIsTaxValidCheck"
          @initBusinessSetting="initBusinessSetting"
        > -->
        <DOPaymentTarget
          ref="DOPaymentTarget"
          :bl-list="freightBillInfo.blList"
          @businessSetting="businessSetting"
          @setTaxInvoice="setTaxInvoice"
          @setPaidYn="setPaidYn"
          @setIsTaxValidCheck="setIsTaxValidCheck"
        >
        </DOPaymentTarget>
        <FreightBillDmdRadio
          ref="FBDmdRadio"
          v-show="isShow.dmdRadio"
          :dmd-cat-cd="freightBillInfo.dmdCatCd"
          :ori-dmd-cat-cd="oriDmdCatCd"
          @child="getChildData"
        >
        </FreightBillDmdRadio>
        <FreightBillInfo
          ref="FBInfo"
          v-show="isShow.info"
          :dp-id="freightBillInfo.dpId"
          :bl-list="freightBillInfo.blList"
          :collect-bl-list="freightBillInfo.collectBlList"
          @child="getChildData"
        ></FreightBillInfo>
        <FreightBillMethod
          ref="FBMethod"
          v-show="isShow.method"
          :err-arr="errArr.method"
          :clear-arr="clearArr.method"
          :dp-id="freightBillInfo.dpId"
          :btn-type="freightBillInfo.btnType"
          @child="getChildData"
        ></FreightBillMethod>
        <FreightBillTax
          ref="FBTax"
          v-show="isShow.taxTemplate"
          :err-arr="errArr.taxTemplate"
          :clear-arr="clearArr.taxTemplate"
          :dp-id="freightBillInfo.dpId"
          :btn-type="freightBillInfo.btnType"
          :validation-tax-template="validationTaxTemplate"
          :bl-list="freightBillInfo.blList"
          @setReqCont="setReqCont"
        ></FreightBillTax>
      </div>

      <div class="mt10 text_center">
        <a class="button blue lg" href="javascript:void(0)" @click="payment()">{{ $t('msg.ONIM099P010.002') }}</a> <!-- 발행 -->
        <a class="button gray lg" href="javascript:void(0)" @click="close()">{{ $t('msg.ONIM099P010.003') }}</a> <!-- 취소 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
//세금계산서 모듈 관련
import CalcIssue from '@/api/rest/settle/calcIssue'
import doPayment from '@/api/rest/trans/do'
import DOPaymentTarget from '@/pages/trans/popup/DOPaymentTarget'
import FreightBillInfo from '@/components/common/FreightBillInfo'
import FreightBillMethod from '@/components/common/FreightBillMethod'
import FreightBillTax from '@/components/common/FreightBillTax'
import FreightBillDmdRadio from '@/components/common/FreightBillDmdRadio'
import { rootComputed } from '@/store/helpers'

let erp_popup = ''

export default {
  name: 'DOPaymentPop',
  components: {
    DOPaymentTarget,
    FreightBillInfo,
    FreightBillMethod,
    FreightBillTax,
    FreightBillDmdRadio
  },
  props: {
    freightBillInfo: {
      type: Object,
      default: function () {
        return {
          blList: [],
          dpId: '',
          dmdCatCd: '',
          btnType: '',
          hasNotPaid: true,
          hasNotTaxInvoice: false,
          collectBlList: []
        }
      }
    }
  },
  data: function () {
    return {
      errArr: {
        info: [],
        method: [],
        taxTemplate: []
      },
      clearArr: {
        info: [],
        method: [],
        taxTemplate: []
      },
      isShow: { //세금계산서 화면 제어
        info: true, //결제정보
        method: true, //결제수단 선택
        taxTemplate: true, //세금계산서 공급 받는 자
        dmdRadio: true
      },
      paidYn: false,
      oriDmdCatCd: null,
      isInit: false,
      isTaxValidCheck: false,
      payType: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    freightBillInfo: {
      deep: true,
      handler () {
        this.getIsShow()
      }
    },
    'isShow.taxTemplate' (val) {
      // 세금계산서 항목이 숨겨지면 값을 초기화한다.
      if (!val) {
        this.$refs.FBTax.reset()
      }
    }
  },
  created () {
  },
  mounted: function () {
    setTimeout(() => {
      this.isInit = false
      this.getIsShow()
    }, 300)
  },
  methods: {
    getChildData (data) {
      switch (data.type) {
        case 'updateSelectDmdCatCd':
          this.freightBillInfo.dmdCatCd = data.payload.selectDmdCatCd

          this.getIsShow()
          break
        case 'updateMethodPayType':
          this.payType = data.payload.payType
          break
      }
    },
    //결제
    payment () {
      //세금계산서 컴포넌트별 데이터 emit
      let taxTemplate = this.$refs.FBTax.emitData('taxTemplate')
      let method = this.$refs.FBMethod.emitData('method')
      let info = this.$refs.FBInfo.emitData('info')
      let doTargetData = this.$refs.DOPaymentTarget.emitData('do')

      //1399520 월말 청구 청구처 없이 발행불가 처리
      if (this.memberDetail.userCtrCd === 'KR' && this.freightBillInfo.dmdCatCd === 'M') {
        if (this.isEmpty(this.$refs.DOPaymentTarget.formData.bzrgNo)) {
          this.$ekmtcCommon.alertDefault('청구처가 선택되지 않았습니다.')
          return
        }
      }

      // 추가내용이 있는 경우 요청내용에 추가한다.
      if (taxTemplate && taxTemplate.reqAddCont) {
        doTargetData.reqCont = taxTemplate.reqAddCont + '\r\n' + doTargetData.reqCont
      }
      // console.log('>>>>> doTargetData', doTargetData)

      // if (this.memberDetail.userCtrCd === 'JP' || (this.memberDetail.userCtrCd !== 'JP' && this.validationTaxTemplate(taxTemplate, 'payBtn'))) {
      if (this.memberDetail.userCtrCd === 'JP' || (this.memberDetail.userCtrCd !== 'JP' && this.validationInfoMethod(info, method, 'payBtn') && this.validationTaxTemplate(taxTemplate, 'payBtn'))) {
        let req = this.parseReqData(taxTemplate, method, info, doTargetData)
        req.reqUsrNm = this.$refs.DOPaymentTarget.formData.reqUsrNm
        req.reqTelNo = this.$refs.DOPaymentTarget.formData.reqTelNo
        req.mbpOfcNo = this.$refs.DOPaymentTarget.formData.reqTelNo

        //미정산 금액 없을 때 발행요청만 처리 or 건별, 월말화주일 때 발행요청만 처리 or 청구처 사업자번호 없는 경우 발행요청만 처리
        //dmdCatCd = 09,10
        if (!this.freightBillInfo.hasNotPaid || (this.freightBillInfo.dmdCatCd === 'M' || this.freightBillInfo.dmdCatCd === 'C') || !this.freightBillInfo.dmdCatCd) {
          if (this.memberDetail.userCtrCd === 'KR') {
            req.powerOfAttName = this.isEmpty(this.$refs.DOPaymentTarget.formData.uploadFileInfos) ? '' : this.$refs.DOPaymentTarget.formData.uploadFileInfos[0].originalName
          }
          this.bankingInfoAndDoRequest(req)
        } else {
          //결제수단(직접입금, 가상계좌, 즉시이체) 선택별 API 호출
          if (method.payType === 'D' && this.isShow.method) { //직접입금
            this.bankingInfoAndDoRequest(req)
          } else { //가상계좌, 즉시이체
            this.pay(req, method, info)
          }
        }
      }
    },
    async reqContDORequest (req) {
      await CalcIssue.bankingInfoAndDoRequest(req).then(response => {
        if (response.data.errCode === '2000') {
          this.$ekmtcCommon.alertDefault(this.$t('art.CSBL220.017')) // 정상적으로 신청되었습니다.
        }
      })
    },
    //D/O 요청 API 호출 - 34
    bankingInfoAndDoRequest (req) {
      CalcIssue.bankingInfoAndDoRequest(req).then(response => {
        if (response.data.errCode === '2000' || response.data.errCode === '2001') {
          const resData = { type: req.type, reqRno: '' }
          this.$refs.DOPaymentTarget.fnPayment(resData).then((result) => {
            if (result.data === 1) {
              if (response.data.errCode === '2001') {
               this.$ekmtcCommon.confirmCallBack('D/O가 발행되었습니다.\n출력 하시겠습니까?', () => {
               this.$store.commit('settle/updateIsSettleSearchData', true)
               this.$parent.$parent.$parent.fnAction('DO_PRINT')
               this.close()
              }, () => {
              this.$store.commit('settle/updateIsSettleSearchData', true)
               this.close()
              })
              } else {
               this.$ekmtcCommon.alertDefault(this.$t('art.CSBL220.017')) // 정상적으로 신청되었습니다.
               this.$store.commit('settle/updateIsSettleSearchData', true)
               this.close()
              }
            } else if (result.data === 100) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ADD20220221.027')) // '은행입금정보가 이미 입력되었습니다.'
              this.close()
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('art.CSBL220.018'))
              this.close()
            }
            //this.close()
          })
        } else if (response.data.errCode === '9002') {
          this.msgAlert(this.$t('art.CSBL220.009'))
        } else if (response.data.errCode === '9003') {
          this.msgAlert(this.$t('art.CSBL220.010'))
        } else {
          this.msgAlert(this.$t('art.CSBL220.015'))
        }
      }).catch(e => {
        this.msgAlert(this.$t('art.CSBL220.015'))
        console.log(e)
      })
    },
    //[가상계좌, 즉시이체]시 - 34
    async pay (req, method, info) {
      await CalcIssue.pay(req).then(async response => {
        if (response.data.errCode === '2000') {
          const resData = { type: req.type, reqRno: '' }
          await this.$refs.DOPaymentTarget.fnPayment(resData).then((result) => {
            if (result.data === 1) {
              this.$ekmtcCommon.alertDefault(this.$t('art.CSBL220.017')) // 정상적으로 신청되었습니다.
              this.$store.commit('settle/updateIsSettleSearchData', true)
            } else if (result.data === 100) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ADD20220221.027')) // '은행입금정보가 이미 입력되었습니다.'
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('art.CSBL220.018'))
            }
            this.close()
          })
        } else if (response.data.errCode === '9002') {
          this.msgAlert(this.$t('art.CSBL220.009'))
        } else if (response.data.errCode === '9003') {
          this.msgAlert(this.$t('art.CSBL220.010'))
        } else {
          this.msgAlert(this.$t('art.CSBL220.015'))
        }

        const res = response.data

        if (req.type === 'N') {
          //즉시이체시 ERP 호출
          this.fnErpSave(res.acNo, res.erpReqNo, res.cnt, method, info)
        }

        // this.msgAlert(this.$t('art.CSBL220.017')) // 정상적으로 신청되었습니다.
        // this.$store.commit('settle/updateIsSettleSearchData', true)
        // this.close()
      }).catch(e => {
        this.msgAlert(this.$t('art.CSBL220.015'))
        //this.cancelPay(response.data.acNo)
        console.log(e)
      })
    },
    //전체데이터 셋팅
    parseReqData (taxTemplate, method, info, doTargetData) {
      let res = {}
      let vrAccNoList = []

      taxTemplate.reqCont = doTargetData.reqCont
      // if (this.freightBillInfo.dmdCatCd === 'C' || method.payType === 'V' || method.payType === 'N') {
      //   const dt = new Date()
      //   const year = dt.getFullYear()
      //   const month = ('0' + (dt.getMonth() + 1)).slice(-2)
      //   const date = ('0' + dt.getDate()).slice(-2)
      //   taxTemplate.acshIssDt = year + month + date
      // } else {
        if (taxTemplate.acshIssKind === '01') {
          taxTemplate.acshIssDt = this.issueDtInfo.depositDt
        }
      // }
      let blNoArr = info.blList.map((bl) => {
        return bl.blNo
      }).join(',')

      if (method.payType === 'D') {
        res = {
          type: method.payType,
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          blList: info.blList,
          currencyCodeList: ['KRW', 'USD'],
          reqBizCatCd: '', //TODO
          asBlNo: blNoArr,
          blNo: blNoArr,
          payAcctList: method.depositInfoArr,
          csbl220requestVO: {},
          amtSetList: [],
          obrdDtList: [],
          corpNameList: [],
          csdo210vo: {},
          taxTemplate: taxTemplate,
          csbl220frVO: taxTemplate,
          csbl220accVO: {},
          file: taxTemplate.file,
          dmdCatCd: this.freightBillInfo.dmdCatCd,
          overseasFileList: this.isEmpty(method.overseasFileList) ? [] : method.overseasFileList,
          // 기존에 월말청구화주일 경우에만 creditYn이 Y인 것을 청구화주일 경우에도 creditYn이 Y가 되도록 변경
          creditYn: this.freightBillInfo.dmdCatCd === 'C' || this.freightBillInfo.dmdCatCd === 'M' ? 'Y' : 'N'
        }
      } else if (method.payType === 'V') {
        if (!this.isEmpty(method.vrAccount.krw)) {
          vrAccNoList.push(method.vrAccount.krw)
        }
        if (!this.isEmpty(method.vrAccount.usd)) {
          vrAccNoList.push(method.vrAccount.usd)
        }
        if (vrAccNoList.length === 0) {
          this.msgAlert('가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
          return false
        }
        res = {
          type: method.payType,
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          blList: info.blList,
          currencyCodeList: ['KRW', 'USD'],
          asBlNo: blNoArr,
          blNo: blNoArr,
          vrAcctNoList: vrAccNoList,
          obrdDtList: [],
          corpNameList: [],
          amtSetList: [],
          csdo210vo: {},
          taxTemplate: taxTemplate,
          csbl220frVO: taxTemplate,
          csbl220requestVO: {},
          csbl220accVO: {},
          file: taxTemplate.file,
          dmdCatCd: this.freightBillInfo.dmdCatCd,
          // 기존에 월말청구화주일 경우에만 creditYn이 Y인 것을 청구화주일 경우에도 creditYn이 Y가 되도록 변경
          creditYn: this.freightBillInfo.dmdCatCd === 'C' || this.freightBillInfo.dmdCatCd === 'M' ? 'Y' : 'N'
        }
      } else if (method.payType === 'N') {
        if (!this.isEmpty(method.vrAccount.krw)) {
          vrAccNoList.push(method.vrAccount.krw)
        }
        if (!this.isEmpty(method.vrAccount.usd)) {
          vrAccNoList.push(method.vrAccount.usd)
        }
        res = {
          type: method.payType,
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          blList: info.blList,
          currencyCodeList: ['KRW', 'USD'],
          asBlNo: blNoArr,
          blNo: blNoArr,
          vrAcctNoList: vrAccNoList,
          obrdDtList: [],
          corpNameList: [],
          amtSetList: [],
          csdo210vo: {},
          taxTemplate: taxTemplate,
          csbl220frVO: taxTemplate,
          csbl220requestVO: {},
          csbl220accVO: {},
          erpBankCode: method.bankLoginInfo.bnkAcct,
          erpBankName: '',
          file: taxTemplate.file,
          dmdCatCd: this.freightBillInfo.dmdCatCd,
          // 기존에 월말청구화주일 경우에만 creditYn이 Y인 것을 청구화주일 경우에도 creditYn이 Y가 되도록 변경
          creditYn: this.freightBillInfo.dmdCatCd === 'C' || this.freightBillInfo.dmdCatCd === 'M' ? 'Y' : 'N'
        }
      }

      res.reqBizCatCd = '07'
      res.payType = method.payType

      return res
    },
    fnErpSave (reqAcNo, erpKmtcNo, cnt, method, info) {
      let data = {
        userId: method.bankLoginInfo.bnkId,
        userPw: method.bankLoginInfo.bnkPswd,
        acNo: method.bankLoginInfo.bnkAcct,
        mnrcvBnkCd: '088',
        mnrcvAcNo: '26204000149',
        amt: info.krwTotal,
        inptDraweName: reqAcNo,
        AcnoMemo: '',
        erpKmtcNo: erpKmtcNo
      }
      CalcIssue.tenUnderTransfer(data).then(response => {
          let resp = response.data
          if (resp.rtnCode !== '0000') {
            this.msgAlert(resp.rtnMsg)
          } else {
            if (!this.isEmpty(resp.outRecDto) && !this.isEmpty(resp.outRecDto.rtnCode) && !this.isEmpty(resp.outRecDto.rtnCode)) {
              this.cancelPay('N', acNo)
            } else {
              //openPopup
              this.openErpPopup(cnt, response, data)
            }
          }
      }).catch(e => {
        this.msgAlert(this.$t('art.CSBL220.015'))
        this.cancelPay('N', reqAcNo)
        console.log(e)
      })
    },
    openErpPopup (cnt, response, data) {
      if (cnt === 1) {
        let url = 'https://cms.kbstar.com/quics?page=C066733'
        let name = this.$t('msg.SETT010T010.084')
        let option = 'width = 880, height = 700, top = 100, left = 500, location = no,scrollbars=yes'
        erp_popup = window.open(url, name, option)
      } else {
        let url = 'https://cms.kbstar.com/quics?page=C102879'
        let name = '간편 이체'
        let option = 'width = 360, height = 460, top = 100, left = 500, location = no,scrollbars=yes'
        erp_popup = window.open(url, name, option)
      }
      this.chk_popup(response, data)
    },
    chk_popup (response, data) {
      if (typeof (erp_popup) === 'undefined' || erp_popup.closed) {
        this.erpResult(response, data)
      } else {
        setTimeout(() => {
          this.chk_popup(response, data)
        }, 3000)
      }
    },
    cancelPay (type, acNo) {
      CalcIssue.cancelPay({ type: type, acNo: acNo }).then(response => {
        console.log(response)
      }).catch(e => {
        console.log(e)
      })
    },
    erpResult (r, d) {
      let data = {
        userId: d.userId,
        userPw: d.userPw,
        erpregno: r.erpregno,
        erpAccNo: d.acNo
      }
      CalcIssue.tenUnderSel(data).then(response => {
        if (response.PrcssFnshYn === 'Y') {
          this.fnErpRegNoEdit(response.ERPRegiPrcssNo)
        }
      }).catch(e => {
        console.log(e)
      })
    },
    fnErpRegNoEdit (erpNo) {
      //
    },
    //세금계산서 결제수단 선택 밸리데이션
    validationMethod (method, type) {
      return true
    },
    //세금계산서 결제정보, 결제수단 선택 밸리데이션
    validationInfoMethod (info, method, type) {
      let errArr = []
      let clearArr = []
     if (this.isShow.method && this.freightBillInfo.dmdCatCd === 'R') {
        if (method.payType === 'D') {
          for (let row of method.depositInfoArr) {
            if (this.isEmpty(row.payCur)) {
              errArr.push({ id: 'fbt-payCur-' + row.rowIdx, msg: this.$t('msg.CSBL220.017') }) // 화폐를 선택해주세요.
            } else {
              clearArr.push('fbt-payCur-' + row.rowIdx)
            }

            if (this.isEmpty(row.bank)) {
              errArr.push({ id: 'fbt-bank-' + row.rowIdx, msg: this.$t('msg.CSBL220.018') }) // 은행을 선택해주세요.
            } else {
              clearArr.push('fbt-bank-' + row.rowIdx)
            }

            if (this.isEmpty(row.accNo)) {
              errArr.push({ id: 'fbt-accNo-' + row.rowIdx, msg: this.$t('msg.CSBL220.019') }) // 계좌번호를 선택해주세요.
            } else {
              clearArr.push('fbt-accNo-' + row.rowIdx)
            }
            if (this.isEmpty(row.depositPnm)) {
              errArr.push({ id: 'fbt-depositPnm-' + row.rowIdx, msg: this.$t('msg.CSBL220.020') }) // 입금자를 입력해주세요.
            } else {
              clearArr.push('fbt-depositPnm-' + row.rowIdx)
            }

            if (this.isEmpty(row.depositAmt)) {
              errArr.push({ id: 'fbt-depositAmt-' + row.rowIdx, msg: this.$t('msg.CSBL220.021') }) // 금액을 입력해주세요.
            } else {
              clearArr.push('fbt-depositAmt-' + row.rowIdx)
            }

            if (this.$ekmtcCommon.isEmpty(row.depositDt)) {
              errArr.push({ id: 'fbt-depositDt-' + row.rowIdx, msg: this.$t('msg.CSBL220.022') }) // 입금일을 입력해주세요.
            } else {
              clearArr.push('fbt-depositDt-' + row.rowIdx)
            }
          }
        } else if (method.payType === 'V') {
          //
          let isExistKrw = false
          let isExistKrwUsd = false

          if (this.isEmpty(method.vrAccount.krw) && this.isEmpty(method.vrAccount.usd)) {
            this.msgAlert('가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
            return false
          }

          if (info.blList !== null && info.blList.length > 0) {
            for (let i = 0; i < info.blList.length; i++) {
              if (info.blList[i].currency === 'KRW') {
                isExistKrw = true
              } else if (info.blList[i].currency === 'KRW+USD') {
                isExistKrw = true
                isExistKrwUsd = true
              }
            }
          }

          if (isExistKrw && this.isEmpty(method.vrAccount.krw)) {
            this.msgAlert('KRW 가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
            return false
          }

          if (isExistKrwUsd && this.isEmpty(method.vrAccount.usd)) {
            this.msgAlert('USD 가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
            return false
          }
        } else if (method.payType === 'N') {
          //
          if (info.usdTotal > 0) {
            this.msgAlert('즉시이체는 KRW 결제만 가능합니다.')
            return false
          }
        }

        this.clearArr.method = clearArr
        if (errArr.length === 0) {
          return true
        } else {
          if (type === 'payBtn') {
            this.errArr.method = errArr
          }
          return false
        }
      }

      return true
    },
    validationTaxTemplate (taxTemplate, type) {
      let errArr = []
      let clearArr = []
      if (this.isShow.taxTemplate && this.isTaxValidCheck) {
        if (this.isEmpty(taxTemplate.rcivNm)) {
          errArr.push({ id: 'fbt-rcivNm', msg: this.$t('msg.ONIM050G020.017') }) //'상호를 입력해주세요.'
        } else {
          clearArr.push('fbt-rcivNm')
        }
        if (this.isEmpty(taxTemplate.rcivAddr)) {
          errArr.push({ id: 'fbt-rcivAddr', msg: this.$t('msg.ONIM050G020.034') }) //'주소를 입력해주세요.'
        } else {
          clearArr.push('fbt-rcivAddr')
        }
        if (this.isEmpty(taxTemplate.rcivBzrgNo)) {
          errArr.push({ id: 'fbt-rcivBzrgNo', msg: this.$t('msg.ONIM050G020.035') }) //'사업자 번호를 입력해주세요.'
        } else {
          clearArr.push('fbt-rcivBzrgNo')
        }
        // if (this.isEmpty(taxTemplate.file)) {
        //   errArr.push({ id: 'fbt-file', msg: '사업자 등록증을 첨부해주세요.' })
        // } else {
        //   clearArr.push('fbt-file')
        // }
        if (this.isEmpty(taxTemplate.emlAddr)) {
          errArr.push({ id: 'fbt-emlAddr', msg: this.$t('msg.ONIM050G020.018') }) //'email을 입력해주세요.'
        } else {
          // let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
          let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (taxTemplate.emlAddr.match(regExp) == null) {
            errArr.push({ id: 'fbt-emlAddr', msg: this.$t('msg.REPORT_PDF.111') })
          } else {
            clearArr.push('fbt-emlAddr')
          }
        }
        if (this.isEmpty(taxTemplate.rcivPicNm)) {
          errArr.push({ id: 'fbt-rcivPicNm', msg: this.$t('js.bookingValidation.026') }) //'담당자를 입력해주세요.'
        } else {
          clearArr.push('fbt-rcivPicNm')
        }
        if (this.isEmpty(taxTemplate.rcivCtfc)) {
          errArr.push({ id: 'fbt-rcivCtfc', msg: this.$t('msg.ONEX060P012.082') })
        } else {
          clearArr.push('fbt-rcivCtfc')
        }

        if (taxTemplate.acshIssKind === '02' && this.isEmpty(taxTemplate.acshIssDt)) {
          errArr.push({ id: 'fbt-acshIssDt', msg: this.$t('msg.ONIM050G020.033') }) //'계산서 발행일을 입력해주세요.'
        } else {
          clearArr.push('fbt-acshIssDt')
        }

        this.clearArr.taxTemplate = clearArr
        if (errArr.length === 0) {
          return true
        } else {
          if (type === 'payBtn') {
            this.errArr.taxTemplate = errArr
          }
          return false
        }
      } else {
        return true
      }
    },
    close () {
      this.$emit('close')
      this.$refs.FBMethod.reset()
      this.$refs.FBTax.reset()
      this.$refs.DOPaymentTarget.reset()
      this.isInit = false
      // this.customComponent = null
      // this.$ekmtcCommon.layerClose('#layerPop .popup_dim')
    },
    msgAlert (msg) {
       let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.MYIN040G010.045'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    getIsShow () {
      let dmdCatCd = this.freightBillInfo.dmdCatCd
      if (!this.isInit) {
         this.oriDmdCatCd = dmdCatCd
         this.isInit = true
      }
      let isShow = {
        info: true,
        method: this.freightBillInfo.hasNotPaid,
        taxTemplate: this.freightBillInfo.hasNotTaxInvoice,
        dmdRadio: true
      }

      switch (this.freightBillInfo.btnType) {
        case 'FREIGHT':
          if (dmdCatCd !== 'R') { //영수화주가 아닐때
            if (!this.freightBillInfo.hasNotTaxInvoice) {
              isShow.taxTemplate = false
            }
          }
          break
        case 'TAX_INVOICE':
          //
          break
        case 'DO':
          if (dmdCatCd === 'R') { //영수화주
              if (this.freightBillInfo.hasNotPaid) {
                isShow.info = true
                isShow.taxTemplate = true
              } else {
                isShow.info = false
                isShow.taxTemplate = false
              }
          } else if (dmdCatCd === 'C') { //청구화주
            isShow.method = false
            if (this.freightBillInfo.hasNotTaxInvoice) {
              if (this.freightBillInfo.hasNotPaid) {
                isShow.info = true
                isShow.taxTemplate = true
              } else {
                isShow.info = false
                isShow.taxTemplate = false
              }
            } else {
              isShow.info = false
              isShow.taxTemplate = false
            }
          } else if (dmdCatCd === 'M') { //월말화주
            isShow = {
              info: false,
              method: false,
              taxTemplate: false
            }
          } else {
            isShow = {
              info: false,
              method: false,
              taxTemplate: false
            }
          }

          isShow.dmdRadio = true
          break
      }

      this.isShow = isShow
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    async businessSetting (obj) {
      console.log('businessSetting obj >>> ', obj)
      this.$refs.FBTax.taxTemplate.rcivBzrgNo = obj.bzrgNo
      this.$refs.FBTax.taxTemplate.rcivCtfc = obj.telNo
      this.$refs.FBTax.taxTemplate.rcivAddr = obj.addr
      this.$refs.FBTax.taxTemplate.rcivNm = obj.bsnNm

      //청구처 선택에 따른 세금계산서 모듈 활성화
      if (obj.bzrgNo) {
        let result = await doPayment.getShipperDmdInfo(obj)

        console.log('getShipperDmdInfo result >>> ', result)

        if (!this.isEmpty(result.data.imDmdCatCd)) {
          setTimeout(() => {
            this.oriDmdCatCd = JSON.parse(JSON.stringify(this.freightBillInfo.dmdCatCd))
          }, 300)
          this.freightBillInfo.dmdCatCd = result.data.imDmdCatCd
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ADD20220221.028')) //'해당 사업자 번호는 발행이 불가능한 사업자 번호입니다. 사업자 번호를 다시 선택해주세요.'
          this.freightBillInfo.dmdCatCd = ''
        }
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ADD20220221.029')) //'사업자 번호가 없는 청구처입니다. 다시 선택해주세요.'
        this.freightBillInfo.dmdCatCd = ''
      }

      this.getIsShow()
    },
    setTaxInvoice (frIss) {
      // if (frIss === '01') {
      //   this.freightBillInfo.hasNotTaxInvoice = true
      // } else if (frIss === '02') {
      //   this.freightBillInfo.hasNotTaxInvoice = false
      // }
      // 세금계산서 미발행인 계산서가 존재할 경우 세금계산서 항목 오픈
      if (frIss === '01') {
        this.freightBillInfo.hasNotTaxInvoice = true
      }
    },
    setPaidYn (flag) {
      this.freightBillInfo.hasNotTaxInvoice = flag
      this.getIsShow()
    },
    setIsTaxValidCheck (flag) {
      this.isTaxValidCheck = flag
    },
    async initBusinessSetting (obj) {
      console.log('initBusinessSetting obj >>> ', obj)

      this.$refs.FBTax.taxTemplate.rcivBzrgNo = obj.bzrgNo
      this.$refs.FBTax.taxTemplate.rcivCtfc = obj.telNo
      this.$refs.FBTax.taxTemplate.rcivAddr = obj.addr
      this.$refs.FBTax.taxTemplate.rcivNm = obj.bsnNm

      this.freightBillInfo.dmdCatCd = this.oriDmdCatCd

      this.getIsShow()
    },
    setReqCont (reqCont, rcvProcCatCd) {
      // 개별발행, 합산발행 변경에 따른 요청내용 변경
      console.log('>>>>> BLPaymentPop : ' + reqCont + ', ' + rcvProcCatCd)
      this.$refs.DOPaymentTarget.setRcqCont(reqCont, rcvProcCatCd)
    }
  }
}
</script>
